import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`React Native SDK v2 Releases`}</h1>
    <h2>{`2.1.1`}</h2>
    <ul>
      <li parentName="ul">{`No relevant changes for this product`}</li>
    </ul>
    <h2>{`2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add card enrollment & verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Renamed the `}<inlineCode parentName="li">{`apiKey`}</inlineCode>{` property to `}<inlineCode parentName="li">{`sdkKey`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property to specify the program type that you'll use in your app. This property determines the card enrollment flow that the SDK presents.`}</li>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`Fidel.setOptions`}</inlineCode>{` function. All SDK properties are now being set via the `}<inlineCode parentName="li">{`Fidel.setup`}</inlineCode>{` function. Please check our Reference documentation to see a complete list of the SDK properties.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`privacyUrl`}</inlineCode>{` property has been renamed to `}<inlineCode parentName="li">{`privacyPolicyUrl`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`termsConditionsUrl`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`termsAndConditionsUrl`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`Fidel.openForm`}</inlineCode>{` function has been renamed to `}<inlineCode parentName="li">{`Fidel.start`}</inlineCode>{`. The callback parameter of this function has been moved. It is now the second parameter of the `}<inlineCode parentName="li">{`Fidel.setup`}</inlineCode>{` function. The callback received results also have changed. Please check our reference documentation for more details.`}</li>
      <li parentName="ul">{`Removed the card scanning feature of the SDK as it was not working well. That's why we also removed the `}<inlineCode parentName="li">{`autoScan`}</inlineCode>{` SDK property.`}</li>
      <li parentName="ul">{`Added support for cards issued in Norway, so you can add the `}<inlineCode parentName="li">{`Fidel.Country.norway`}</inlineCode>{` to your `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` array.`}</li>
      <li parentName="ul">{`For the Android library:`}
        <ol parentName="li">
          <li parentName="ol">{`It does not use `}<inlineCode parentName="li">{`jCenter()`}</inlineCode>{` repository anymore, but `}<inlineCode parentName="li">{`mavenCentral()`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Updated `}<inlineCode parentName="li">{`targetSdkVersion`}</inlineCode>{` version to `}<inlineCode parentName="li">{`32`}</inlineCode>{`.`}</li>
        </ol>
      </li>
      <li parentName="ul">{`Added a lot of new features for our Transaction Stream product, which requires card verification. These features are not useful for Select Transactions/Loyalty use cases. Please check the Transaction Stream documentation for more details.`}</li>
    </ul>
    <h1>{`React Native SDK v1 Releases`}</h1>
    <h2>{`1.6.4`}</h2>
    <ul>
      <li parentName="ul">{`Updated the consent text for United States and Canadian issued cards.`}</li>
    </ul>
    <h2>{`1.6.3`}</h2>
    <ul>
      <li parentName="ul">{`Replace "Fidel" with "Fidel API" in the consent text.`}</li>
      <li parentName="ul">{`Allow expiration date editing on Android without switching to country selection.`}</li>
    </ul>
    <h2>{`1.6.2`}</h2>
    <ul>
      <li parentName="ul">{`Update Fidel API logo`}</li>
    </ul>
    <h2>{`1.6.2`}</h2>
    <ul>
      <li parentName="ul">{`Update Fidel API logo`}</li>
    </ul>
    <h2>{`1.6.1`}</h2>
    <ul>
      <li parentName="ul">{`Always provide the `}<inlineCode parentName="li">{`scheme`}</inlineCode>{` field in Android, after successful card enrollments.`}</li>
      <li parentName="ul">{`Provide support for `}<inlineCode parentName="li">{`resConfigs`}</inlineCode>{` optimization parameter in Android.`}</li>
    </ul>
    <h2>{`1.6.0`}</h2>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`defaultSelectedCountry`}</inlineCode>{` property which sets the country that will be selected by default, when opening the card enrollment screen.`}</li>
    </ul>
    <h2>{`1.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Remove card scanning confirmation screen.`}</li>
    </ul>
    <h2>{`1.4.3`}</h2>
    <ul>
      <li parentName="ul">{`Add United Arab Emirates option as a country of issuance.`}</li>
      <li parentName="ul">{`Country label shrinks its font size, to fit longer country names, on smaller devices.`}</li>
    </ul>
    <h2>{`1.4.2`}</h2>
    <ul>
      <li parentName="ul">{`Update Fidel Android SDK version to allow French & Swedish translations be available in more countries.`}</li>
    </ul>
    <h2>{`1.4.1`}</h2>
    <ul>
      <li parentName="ul">{`Update Fidel & Android SDK versions.`}</li>
      <li parentName="ul">{`Prepare for the newest React Native versions.`}</li>
      <li parentName="ul">{`Fix some unit tests.`}</li>
      <li parentName="ul">{`Made it easier to automate testing this React Native bridge library.`}</li>
    </ul>
    <h2>{`1.4.0`}</h2>
    <ul>
      <li parentName="ul">{`Now the SDK allows you to select multiple allowed countries from which the user can pick. Please check the docs for information about the new `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` option.`}</li>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`country`}</inlineCode>{` option. To set a default country and not allow the user to pick the country, set a single country in the new `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` option. Check the example project or the README docs to see how to do that.`}</li>
      <li parentName="ul">{`If available, the linking result object now includes the `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` field. So, if in the Fidel Dashboard, under your security settings, you allow showing the first numbers of the linked card numbers, the information will be available in the linking result object too. If you do not allow showing the first numbers in the linking result, the `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` field will return `}<inlineCode parentName="li">{`"******"`}</inlineCode>{` (just like the object which the Fidel API returns).`}</li>
    </ul>
    <h2>{`1.3.1`}</h2>
    <ul>
      <li parentName="ul">{`Fixes an Android issue in the latest React Native versions, when specifying the banner image in release environment.`}</li>
      <li parentName="ul">{`Checking for `}<inlineCode parentName="li">{`null`}</inlineCode>{` values, in mandatory SDK fields, to prevent unclear bugs.`}</li>
    </ul>
    <h2>{`1.3.0`}</h2>
    <ul>
      <li parentName="ul">{`Localised the SDK for French and Swedish users.`}</li>
      <li parentName="ul">{`The terms & conditions text now adjusts to the card scheme name that the user inputs (Visa, MasterCard or Amex).`}</li>
      <li parentName="ul">{`If you set the default country of the SDK to USA or Canada (with `}<inlineCode parentName="li">{`country`}</inlineCode>{` option) or, if you do not set a default country, the terms and conditions text will adjust depending on the country you have set. For USA & Canada, the following would be an example Terms & Conditions text, for Cashback Inc (an example company):`}</li>
    </ul>
    <p><em parentName="p">{`By submitting your card information and checking this box, you authorize Visa to monitor and share transaction data with Fidel (our service provider) to participate in  program. You also acknowledge and agree that Fidel may share certain details of your qualifying transactions with Cashback Inc to enable your participation in  program and for other purposes in accordance with the Cashback Inc Terms and Conditions, Cashback Inc privacy policy and Fidel’s Privacy Policy. You may opt-out of transaction monitoring on the linked card at any time by contacting support.`}</em></p>
    <p>{`For the rest of the world:`}</p>
    <p><em parentName="p">{`I authorise Visa to monitor my payment card to identify transactions that qualify for a reward and for Visa to share such information with Cashback Inc, to enable my card linked offers and target offers that may be of interest to me. For information about Cashback Inc privacy practices, please see the privacy policy. You may opt-out of transaction monitoring on the payment card you entered at any time by contacting support.`}</em></p>
    <ul>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programName`}</inlineCode>{` and `}<inlineCode parentName="li">{`termsConditionsUrl`}</inlineCode>{` options. They are used when building the new USA / Canada specific terms and conditions text that the user must agree with, before linking a card. If you set the `}<inlineCode parentName="li">{`country`}</inlineCode>{` option to a USA or Canada, it's mandatory for you to provide your terms and conditions URL via `}<inlineCode parentName="li">{`termsConditionsUrl`}</inlineCode>{`. If you do not provide it, you will receive an error when you try to open Fidel's linking screen.`}</li>
      <li parentName="ul">{`Fixed bug that returned an empty link result in Android, when linking is successful.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      